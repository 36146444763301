import React from "react";
import {Link, graphql, useStaticQuery} from "gatsby";
import {PastEventContainer} from "../../style";

const PastEvents = (props) => {
  const {putSpace, showViewMore} = props;
  const {allVideo: {edges}} = useStaticQuery(graphql`
    query VideoQuery {
      allVideo(
        sort: {
          order: DESC, 
          fields: snippet___publishedAt
        }
      ) {
        edges {
          node {
            videoId
            snippet {
              description
              title
            }
          }
        }
      }
    }
  `);

  const pastWebinars = edges.reduce((data, el) => {
    const {node: {videoId, snippet: {title, description}}} = el;
    data.push({
      title,
      description,
      link: `https://www.youtube.com/watch?v=${videoId}`
    })
    return data;
  }, []);

  return (
    <PastEventContainer
      className={`past-event-container${putSpace ? " section-spacing" : ""}`}
    >
      <h2 className="section-heading" id="past-events">Past Events</h2>
      <div className="grid-container">
        {
          pastWebinars.map((el, index) => {
            const {title, description, link} = el;
            if(showViewMore && index > 1){
              return undefined;
            }
            return (
              <div className="event" key={title}>
                <a target="_blank" rel="noreferrer" href={link}>
                  <h3 className="name" dangerouslySetInnerHTML={{__html: title}}></h3>
                  <p className="speaker">{description}</p>
                </a>
              </div>
            );
          })
        }
      </div>
      {showViewMore && 
        <div className="view-more-container">
          <Link className="btn reverse" to="/events/#past-events" target="_self">View More</Link>
        </div>
      }
    </PastEventContainer>
  );
};

PastEvents.defaultProps = {
  putSpace: false,
  showViewMore: false
};

export default PastEvents; 