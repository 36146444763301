import React, {Fragment} from "react";
import moment from "moment";
import CPOEMNewsLetter from "./cpoemNewsletter";
import {Link, graphql, useStaticQuery} from "gatsby";
import {CenteredContainer, WebinarContainer} from "../../style";
import interactiveEventBadge from "../../assets/images/interactive-event-badge.svg";

const InteractiveEvents = (props) => {
  const {showViewMore, putSpace} = props;
  const {allWebinar: {edges}} = useStaticQuery(graphql`
    query UpcomingWebinarQuery {
      allWebinar(
        sort: {order: ASC, fields: start_time}
      ) {
        edges {
          node {
            topic
            agenda
            start_time
            registration_url
            speakers {
              img
              name
              desc
            }
          }
        }
      }
    }
  `);

  const upcomingWebinars = edges.reduce((data, el) => {
    const {node: {start_time, topic, agenda, registration_url: registrationUrl, speakers}} = el;
    if(new Date(start_time).getTime() > new Date().getTime()){
      const splitAgenda = agenda.split("Highlights:");
      const highlights = splitAgenda.pop().trim().split(" | ");
      const desc = splitAgenda.join("");
      
      data.push({
        date: start_time,
        name: topic,
        desc,
        highlights,
        speakers,
        registrationUrl
      })
    }
    return data;
  }, []);

  console.log(upcomingWebinars);

  return (
    <Fragment>
      <CenteredContainer className={`interactive${putSpace ? " section-spacing" : ""}`}>
        <img src={interactiveEventBadge} className="logo" alt="logo"/>
        <h2 className="heading-text">
          <span>Interactive and engaging webinars</span>
          to sharpen skills and improve knowledge
        </h2>
      </CenteredContainer>
      <WebinarContainer className="webinars">
        {
          upcomingWebinars.length === 0 &&
          <CPOEMNewsLetter/>
        }
        <div className="grid-container">
          {
            upcomingWebinars.map((el, index) => {
              const {name, date, desc, highlights, registrationUrl, speakers} = el;
              if(showViewMore && index > 2){
                return undefined;
              }
              return (
                <div
                  key={name + index}
                  className={`webinar ${index === 0 ? "recent" : "upcoming flex-container column"}`}
                >
                  <div className="flex-container">
                    <div className="flexbox">
                      <div className="date">{moment(date).format("dddd D MMM YYYY")}</div>
                      <h2 className="title">{name}</h2>
                      <div className="desc-container">
                        <p className="desc">{desc}</p>
                        {index === 0 && 
                          <div className="highlights">
                            Highlights:
                            {
                              highlights.map(el => <span key={el}>{el}</span>)
                            }
                          </div>
                        }
                      </div>
                    </div>
                    {index === 0 && 
                      <div className="flexbox agenda">
                        <h3 className="heading">Agenda</h3>
                        <ul>
                          {
                            speakers.map(el => {
                              const {name, img, desc} = el;
                              return (
                                <li
                                  className="flex-container"
                                  key={name}
                                >
                                  <div className="image">
                                    <img src={img} className="doctor-pic" alt="doctor pic"/>
                                  </div>
                                  <div className="flexbox agenda-details">
                                    <div className="agenda-desc">{desc}</div>
                                    <div className="doctor-name">{name}</div>
                                  </div>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    }
                  </div>
                  <div className="flex-container column bottom-part justify-end">
                    {index !== 0 && 
                      <div className="speakers">
                        {
                          speakers.map(el => (
                            <span
                              className="speaker"
                              key={el.name}
                            >{el.name}</span>
                          ))
                        }
                      </div>
                    }
                    <div className="btn-container text-right">
                      <a href={registrationUrl} target="_blank" rel="noreferrer"className="btn text-uppercase">Register</a>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        {showViewMore && 
          <div className="view-more-container">
            <Link className="btn reverse" to="/events/" target="_self">View More</Link>
          </div>
        }
      </WebinarContainer>
    </Fragment>
  )
};

export default InteractiveEvents;