import React, {useState, useEffect, Fragment} from "react";
import {emailRegex} from "../common.config";
import {PopupFormContainer} from "../../style";

const PopupForm = (props) => {
  const {setShowForm, onValidated, status, message} = props;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showAlerts, setShowAlerts] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if(name && email){
      const isValid = emailRegex.test(email);
      setIsFormValid(isValid);
    }
  }, [name, email]);

  useEffect(() => {
    if(status === "success" || status === "error"){
      setShowAlerts(true);
    }
  }, [status, message]);

  return (
    <PopupFormContainer
      className="flex-container vertical-middle justify-center"
    >
      <div className="form-container">
        <button
          aria-label="Close modal"
          className="close"
          onClick={() => setShowForm(false)}
        />
        <h2>CPOEM Newsletter</h2>
        <p>
          Be the first to find out about upcoming webinars. 
          Sign up for articles and videos to keep the knowledge flowing.
        </p>
        {showAlerts &&
          <div className={`alert-container ${status}`}>
            {status === "error" &&
              <div className="message" dangerouslySetInnerHTML={{ __html: message }}/>
            }
            {status === "success" && 
              <span className="message">
                You have been successfully subscribed to CPOEM Newsletter
              </span>
            }
          </div>
        }
        {!showAlerts &&
          <Fragment>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="MERGE1"
                id="name"
                className="input-field"
                placeholder="Your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                autocapitalize="off"
                autocorrect="off"
                name="MERGE0"
                id="email"
                placeholder="Your email address"
                className="input-field"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button
              disabled={status === "sending" || !isFormValid}
              className="btn deep-green text-uppercase"
              onClick={() => onValidated({
                MERGE0: email,
                MERGE1: name
              })}
            >{status === "sending" ? "Subscribing..." : "Subscribe"}</button>
          </Fragment>
        }
      </div>
    </PopupFormContainer>
  )
};

export default PopupForm;
